.viewInvoices {
    background-color: #FAFAFA; 
    font-family: Roboto;
}

.viewInvoices__container {
    margin: 30px 30px 40px 30px;
    margin: 30px 30px 100px 30px;
    min-height: 600px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.viewInvoices__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: rgba(51, 51, 51, 0.85);
    text-align: center;
    padding-top: 21px;
}

.viewInvoices__horizontalLine {
    width: 100%;
    margin: 20px 0px;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
}

.viewInovices__selectCompany {
    width: 504px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 4px;
    color: rgba(51, 51, 51, 0.85);
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 15px 20px;
    padding-left: 10px;
}

.viewInovices__selectCompany:focus {
    outline: none;
}

.viewInvoices__dateFilter {
    display: flex;
    align-items: center;
}

.viewInvoices__dateFilter > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
    margin: 0 20px
}

.viewInvoices__timePeriod {
    width: 160px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
}

.viewInvoices__calenderImg {
    margin-right: 12px;   
}

.viewInvoices__txnDateSeach {
    width: 100px;
    height: 40px;
    background: #EB5C24;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.viewInvoices__txnDateSeach:focus {
    outline: none;
}

.viewInvoices__tableSection {
    padding: 0 20px 24px 20px;
}

.viewInvoices__filterSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px
}

.viewInvoices__text {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
    margin-right: 10px;
}

.viewInvoices__select {
    width: 80px;
    height: 40px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-left: 10px;
    padding-left: 10px;
}

.viewInvoices__select:focus {
    outline: none;
}

.viewInvoices__inputSearch {
    width: 250px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 4px;
    margin-left: 10px;
    font-size: 14px;
    padding-left: 5px;
    line-height: 16px;
    color: #979797;
}

.viewInvoices__inputSearch::placeholder {
    font-size: 12px;
    padding-left: 5px;
    line-height: 16px;
    color: #979797;
}

.viewInvoices__inputSearch:focus {
    outline: none;
}

.viewInvoices__toggleCols {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.viewInvoices__allCols {
    margin-top: -5px
}

.viewInvoices__deleteIcon {
    border: none;
    background: #FFFFFF;
}

.viewInvoices__deleteIcon:focus {
    outline: none;
}