.dashboard-cont {
    /* background-color: #FAFAFA; */
    padding: 10px 10px;
    height: 100vh;
}
#search-btn {
    width: 40px;
    height: 38px;
    background-color: #EA5D25;
    border-radius: 4px;
    border-color:  #EA5D25;
    margin-left: 10px !important;
}

#search-btn:focus {
    box-shadow: none !important;
}
#search-btn {
    margin-left: 20px !important;
}
#btn-download {
    color: #EB5C24;
    background: #FFFFFF;
    border: none;
}

#btn-download:focus {
    box-shadow: none !important;
}

#btn-download img {
    height: 18px;
    object-fit: contain;
}

.searchttrSelection{
    margin-left: 30px !important;
    margin-right: -24px !important;
}