.login {
    background: white !important;
}

.login__container{
    height: 100vh;
    width: 80%;
    margin-left: 5%;
}
.row{
    height: inherit;
    margin: 0;
    align-items: center;
}

.login__container > .row > div {
    padding: 2em;
    text-align: center;
}

.login__input {
    border: 1px solid #E1E2E4;
    border-radius: 4px;
}

.login__inputImage {
    border: none;
}

/* form {
    padding: 2em 0;
} */
.login__formColumn {
    text-align: right;
    margin-top: 1em;
}

.login__financeLogo {
    margin-bottom: 30px;
}
 
.login__btn {
    background: #EA5D25 !important;
    border-color: #EA5D25 !important;
    padding: 0.5em 3em;
}

.login__btn:focus, .login__btn:active {
    box-shadow: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.login__container .input-group-text {
    background: transparent;
    border-right-width: 0;
}
.login__container .form-control {
    border-left-width: 0;
    padding-left: 0;
}

.login__inputEmail {
    background: white;
    border: none;
}

.login__inputEmail:focus {
    outline: none;
    background-color: white;
    border: none;
    box-shadow: none;
}
.login-banner{
    height: 450px;
    /* height: auto; */
    /* width: 90%; */
}

input.error {
    border-color: red;
  }
  
.login__feedback {
    color: rgb(235, 54, 54);
    margin-top: -15px;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: left;
}