.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    background-color: #282C34;
    padding: 0 30px;
    height: 50px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
}

.footer__left, .footer__right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__left > div, .footer__right > div {
    margin-right: 10px;
    padding-right: 10px;
}

.footer__borderRight {
    border-right: 1px solid rgba(255, 255, 255, 0.85);;
}