.individualInvoice {
    background: #FAFAFA;
}

.individualInvoice__container {
    margin-top: 30px;
}

.individualInvoice__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: rgba(51, 51, 51, 0.85);
    margin-top: 25px;
}

.uploadInvoice__radioContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.uploadInvoice__radioContainer > div {
    margin-right: 50px;
}

.uploadModel__radioContainer {
    display: flex;
    margin-top: 10px;
}

.uploadModel__radioContainer > div {
    margin-right: 50px;
}

.currencyModel__radioContainer {
    display: flex;
    margin-top: 10px; 
    margin-right: 50px;
}

.currencyModel__radioContainer  > div  {
    margin-right: 40px;
}

.individualInvoice__labelText {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
}

input[type='radio'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 50%;
    outline: none;
    border: 1px solid gray;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin: 20% auto;
    border-radius: 50%;
}

input[type="radio"]:checked:before {
    background: #EB5C24;
    
}

input[type="radio"]:checked {
  border-color: #EB5C24;
}

.individualInvoice__btns {
    /* width: 1108px; */
    width: 80%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.individualInvoice__cancelBtn {
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EB5C24;
    border-radius: 4px;
    color: #EB5C24;
}

.individualInvoice__finishBtn {
    width: 100px;
    height: 40px;
    background: #F39672;
    border-radius: 4px;
    border: none;
    color: white;
    margin-left: 15px;
}

.individualInvoice__finishBtn.success {
    width: 100px;
    height: 40px;
    background: #EB5C24;
    border-radius: 4px;
    border: none;
    color: white;
    margin-left: 15px;
}

.individualInvoice__cancelBtn:focus, .individualInvoice__finishBtn:focus {
    outline: none;
}