.sidebar {
    position: absolute;
    z-index: 999;
    /* width: 20%; */
    width: 286px;
    height: 100%;
    background-color: white;
    transition: all 0.5s ease;
}

.sidebar__overlay {
    transition: all 0.5s ease-in;
}

.sidebar__overlay.active {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0,0,0,0.35);
    transition: all 0.5s ease-out;
}

.sidebar.open {
    transform: translateX(-100%);
    margin-left: -200px;
    transition: all 0.5s ease-out;
}

.sidebar__nav {
    display: flex;
    align-items: center;
    height: 72px;
}

.sidebar__nav > button {
    margin-left: 15px;
    margin-right: 25px;
}

.sidebar__list {
    margin-top: 15px;
}

.sidebar__listItem:first-child {
    border-top: 1px solid #E1E2E4;
}

.sidebar__listItem {
    background-color: #FFFFFF;
    height: 55px;
    display: flex;
    align-items: center;
    border-left : 5px solid  #FFFFFF;
    border-bottom: 1px solid #E1E2E4;
}

.sidebar__listItem:hover {
    opacity: 0.8;
}

.sidebar__listItem.active {
    background-color: #EA5D25;
    height: 55px;
    display: flex;
    align-items: center;
    border-left: 5px solid #BC491D;
}

.sidebar__listItem > a {
    display: flex;
}

.sidebar__listItem > a:hover {
    text-decoration: none;
}

.sidebar__listItemText {
    font-size: 18px;
    line-height: 21px;
    color: rgba(51, 51, 51, 0.85);
}

.sidebar__listItemText.active {
    font-size: 18px;
    line-height: 21px;
    color:white;
}

.sidebar__listItem > a > div {
    text-decoration: none;
    margin-top: 4px;
}

.sidebar__listIcon {
    margin: 0 10px 0 20px;
}

.sidebar__bbtEtries {
    background-color: #EA5D25;
    height: 55px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    border-left: 5px solid #BC491D;
}

.sidebar__bbtEtries > h4 {
    font-size: 18px;
    line-height: 21px;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 25px; 
}
.sidebar__bbtEtries img {
    margin-right: 10px;
}
