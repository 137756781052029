.dropdownWrapper {
    font-size: 14px;
    line-height: 16px;
    min-height: 40px;
    /* position: absolute; */
    z-index: 99999;
    color: #979797;
}

.dropdown__selectbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 192px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E1E2E4;
    border-radius: 4px;
    padding: 0px 15px;
}

.dropdown__selectbox-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 192px;
    height: 40px;
    background: #FFFFFF;
    border-top: 1px solid #E1E2E4;
    border-left: 1px solid #E1E2E4;
    border-right: 1px solid #E1E2E4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0px 15px;
}

.dropdown__selectbox:hover {
    cursor: pointer;
}

.dropdown__listWrapper {
    position: absolute;
    z-index: 999;
    width: 192px;
    background: #FFFFFF;
    border-bottom: 1px solid #E1E2E4;
    border-left: 1px solid #E1E2E4;
    border-right: 1px solid #E1E2E4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.dropdown__list {
    list-style: none;
    /* border: 1px solid red; */
    width: 192px;
    /* background: #FFFFFF; */
    /* border: 1px solid #E1E2E4; */
    border-radius: 4px;
    margin-top: 12px;
    padding: 0px 15px;
}

.dropdown__list > li {
    margin-bottom: 15px;;
}

.dropdown__customBtn {
    width: 162px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EB5C24;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: #EB5C24;
    /* margin-top: 10px; */
}

.dropdown__customBtn:focus {
    outline: none;
}

.dropdown__hr {
    /* height: 1px;
    color: #E1E2E4; */
    border-top: 1px solid #E1E2E4;
    margin: 0 15px;
}

.dropdown__btns {
    background: #FFFFFF;
    color: #979797;
    border: none;
    width: 100%;
    text-align: left;
}

.dropdown__btns:focus {
    outline: none
}