* {
    margin: 0;
    padding: 0;
    font-family: Roboto;
}

#navbar {
    display: flex;
    height: 72px;
    background: #FFFFFF;
    padding: 20px 10px 10px 10px;
    box-shadow: 0px 2px 4px  rgba(148, 152, 161, 0.15);
}

#navbar ul {
    list-style: none;
    display: flex;
}

#navbar li {
    display: flex;
    /* margin: 0 10px; */
    /* justify-content: center; */
    align-items: center;
}

.hamburger {
    border: none;
    background:none;
    outline: none;
}

.hamburger:focus {
    outline: none;
}

#itilite-logo {
    margin-left: 20px;
}

.header-name {
    font-size: 22px;
    line-height: 26px;
    color: #EA5D25;
    margin-left: 50px;
}

ul:nth-child(2) {
    margin-left: auto;
}

.navbar__dropdown {
    background: white;
    outline: none;
    border: none;
    color:#666;;
}

.navbar__dropdown:hover {
    background: white !important;
    color: #666 !important;
    border: none !important;
    outline: none !important;
}

.navbar__dropdown:focus {
    background: white !important;
    color: #666 !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}

.navbar__dropdown:active {
    background: none !important;
    outline: none !important;
    color: #666 !important;
    border: none !important;
}

.navbar__dropdownItem:active, .navbar__dropdownItem:focus {
    background: none !important;
    color: #666 !important;
    border: none !important;
}

.show>.btn-primary.dropdown-toggle {
    background: none !important;
    color: #666;
    border: none;
}


/* Dashborad */
.dashboard-cont {
    /* background-color: #FAFAFA; */
    padding: 10px 10px;
    height: 100vh;
}

.input-group-text {
    background: white;
    border-right-width: 0px;
}

.input-group>.form-control {
    border-left-width: 0;
}

/* .dashboard__selectSearch {
    width: 00px;
} */

.dashboard__calenderImg {
    object-fit: contain;
}

.dashboard__timePeriod {
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #E1E2E4;
    border-radius: 4px;
    background-color: white; 
    /* width: 165px; */
    width: 165px;
    height: 40px;
    margin-right: 5px;
}

.react-datepicker__input-container > input {
    border:none;
    outline: none;
    font-size: 14px;
    line-height: 16px;
    color: #979797;
    margin-left: 10px;
    width: 100px;
}

.react-datepicker__navigation:focus {
    outline: none !important;
}

.dashboard__labelText {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
}

.dashboard__selectSearch {
    width: 165px;
    height: 40px;
    font-size: 14px;
    line-height: 16px;
    color: #979797;

}

.dashboard__selectSearch:focus, .dashbaord__searchInput:focus, .dashboard__selectView:focus {
    box-shadow: none !important;
    border-color: #ced4da !important
}

.dashboard__selectSearch > option {
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0;
}

.dashbaord__searchInput {
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    /* width: 160px; */
    width: 165px;
    color: #979797;
    /* padding-left: 15px; */
    margin-left: 13px;
    padding-left: 18px;
}

#search-btn,#reset-btn, #download-btn {
    width: 40px;
    height: 38px;
    background-color: #EA5D25;
    border-radius: 4px;
    border-color:  #EA5D25;
    margin-left: 10px !important;
}

#search-btn:focus, #reset-btn:focus, #download-btn:focus {
    box-shadow: none !important;
}

.custom-column {
    width: 192px;
    height: 40px;
}

.dashboard__selectView {
    height: 40px;
    border: 1px solid #E1E2E4;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: #979797;
}

.dashboard__selectView > option {
    margin: 10px 5px;
}

.dashboard__showEntriesText {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
}

.dashboard__showEntries {
    width: 80px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: #979797;
}

#btn-advance-filter {
    height: 40px;
    background-color: #EA5D25;
    border-radius: 4px;
    border-color:  #EA5D25;
}


.table-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(148, 152, 161, 0.15);
    /* box-shadow: 0px 2px 4px  rgba(148, 152, 161, 0.15); */
    border-radius: 4px;
    font-size: 12px;
}

#btn-download {
    color: #EB5C24;
    background: #FFFFFF;
    border: none;
}

#btn-download:focus {
    box-shadow: none !important;
}

#btn-download img {
    height: 18px;
    object-fit: contain;
}

.dashboard__noDataFound {
    display: grid;
    place-items: center;
    height: 400px;
}

/* .dashboard__spinner {
    height: 10rem;
    width: 10rem;
} */

/* pagination */
.pagination .page-link {
    color: #979797;
    font-size: 14px;
}


/* modal */
.modal-content {
    padding: 50px 25px;
    font-size: 14px;
}

.modal-header-text {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: left;
    color: rgba(51, 51, 51, 0.85);
}

.modal-body-border {
    width: 380px;
    height: 422px;
    border: 1px solid #E1E2E4;
    box-sizing: border-box;
    border-radius: 4px;
    overflow-y: scroll;
}

/* hide scrossbar */
/* .modal-body-border::-webkit-scrollbar {
    display: none;
}

.modal-body-border {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
} */
/* hide scrossbar */


.customViewModal__searchInput {
    border: none;
    /* border-top: hidden;
    border-right: hidden;
    border-left: hidden; */
    padding-left: 0;
}

.customViewModal__searchInput:focus {
    box-shadow: none !important;
    /* border-color: none !important; */
}

.customViewModal__search {
    position: sticky;
    top: 0;
    width: 480px;
    border-bottom: 1px solid #E1E2E4;
    /* margin-bottom: 100px */
}

.customViewModal__ul {
    padding-bottom: 10px;
}

.customViewModal__list {
    padding-bottom: 8px !important;
    margin-left: 10px;
}

.modal-body-border ul {
    list-style: none;
}

.customViewModal__buttons {
    padding-top: 20px;
}

.customViewModal__addCols {
    display: none;
}

.customViewModal__list:hover .customViewModal__addCols {
    display: block;
}

.customViewModal__selectedCols {
    padding-bottom: 10px !important;
}

.customViewModal__selectedCols:hover .customViewModal__addCols {
    display: block;
}

.customViewModal__btnCancle {
    color: #EB5C24;
    background: none;
    border: 1px solid #EB5C24;
    width: 100px;
    height: 40px;
}

.customViewModal__btnCancle:hover {
    background: none !important;
    color: #EB5C24 !important;
    border: 1px solid #EB5C24 !important;
}

.customViewModal__btnCancle:focus {
    background: none !important;
    color: #EB5C24 !important;
    border: 1px solid #EB5C24 !important;
}

.customViewModal__applyBtn {
    background: #EB5C24;
    border: none;
    width: 100px;
    height: 40px;
}

.customViewModal__applyBtn:hover, .customViewModal__applyBtn:active, .customViewModal__applyBtn:focus {
    background: #EB5C24 !important;
    border: none;
    box-shadow: none !important;
}

.btn-add-more {
    border: none;
    background: none;
    color: #0495E7;
    outline: none;
}
.dashboard__leftSearch, .dashboard__rightSearch{
    padding:10px 0px 4px 0px;
}
/* .mb-1{
    padding-top: 20px;
} */
/* .dashbaord__searchInput,.ml-1,.ml-2{
    margin-left: 15px;
} */


/* for 1st release */
.dashbaord__searchInput, #search-btn {
    margin-left: 20px !important;
}

/* .search-btn {
    margin-left: 20px !important;
} */
/* for 1st release */
