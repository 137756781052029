.viewInvoices {
    background-color: #FAFAFA;
    font-family: Roboto;
}

.viewInvoices__container {
    margin: 30px 30px 40px 30px;
    margin: 30px 30px 100px 30px;
    min-height: 600px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.viewInvoices__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: rgba(51, 51, 51, 0.85);
    text-align: center;
    padding-top: 21px;
}

.viewInvoices__horizontalLine {
    width: 100%;
    margin: 20px 0px;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
}

.viewInvoices__tableSection {
    padding: 0 20px 24px 20px;
}

.viewInvoices__filterSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px
}

.viewInvoices__inputSearch {
    width: 250px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.3);
    border-radius: 4px;
    margin-left: 10px;
    font-size: 14px;
    padding-left: 5px;
    line-height: 16px;
    color: #979797;
}

.viewInvoices__inputSearch::placeholder {
    font-size: 12px;
    padding-left: 5px;
    line-height: 16px;
    color: #979797;
}

.viewInvoices__inputSearch:focus {
    outline: none;
}

#refresh-btn{
    background: #EB5C24;
    border: none;
    font-size: 12px;
}

#refresh-btn:focus {
    outline: none;
}

.viewInvoice__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 10px;
}