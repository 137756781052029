
.stepper-container-horizontal {
    width: 70%;
    margin-top: 20px;
    padding: 0 30px;
}


.stepper-wrapper-horizontal {
    display: flex;
    justify-content: space-between;
}

.step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 23%;
    position: relative;
}

.step-number {
    border-radius: 80%;
    /* border: 1px solid gray; */
    width: 42px;
    height: 42px;
    text-align: center;
    padding: 10px;
    margin-bottom: 5px;
}

.divider-line {
    height: 2px;
    background-color: #F4F4F4;
    position: absolute;
    width: 100%;
    top: 30%;
    left: 63%
}

.divider-line-active {
    height: 2px;
    background-color: #F9D0BE;
    position: absolute;
    width: 100%;
    top: 30%;
    left: 63%
}

.divider-line-1 {
    width: 296%;
}

.divider-line-2 {
    width: 125%;
}

.divider-line-3 {
    width: 150%;
}

.divider-line-4 {
    width: 60%;
}

.step-number-active {
    background-color: #EB5C24;
    color: #fff;
}

.step-number-active .step-description {
    color: #979797;
}

.step-number-disabled {
    /* border: 1px solid #838383; */
    background-color: #F4F4F4;
    color: rgba(51, 51, 51, 0.85);
}

.step-description {
    font-weight: normal;
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgba(51, 51, 51, 0.85);
}

.step-description-active {
    /* font-weight: bold; */
    color: rgba(51, 51, 51, 0.85);
}

.step-description.active {
    /* font-weight: bold; */
    color: #979797;
}

.buttons-container {
    text-align: center;
}

.buttons-container > button {
    margin: 0 2rem; 
    width: 100px;
    padding: 4px;
    background-color: lightsalmon;
    border: none;
}