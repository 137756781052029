.deleteInvoice {
    background-color: #FAFAFA;
}

.deleteInvoice__container {
    margin: 30px 30px 40px 30px;
    height: 598px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
}

.deleteInvoice__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: rgba(51, 51, 51, 0.85);
    text-align: center;
    padding-top: 21px;
}

.deleteInvoice__horizontalLine {
    width: 100%;
    margin: 20px 0px;
    border-top: 1px solid rgba(151, 151, 151, 0.3);
}

.deleteInvoice__bbtSl {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: rgba(51, 51, 51, 0.85);
    margin: 20px 0;
}

.deleteInvoice__tableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 427px; */
}

.deleteInvoice_table, .deleteInvoice_table td, .deleteInvoice_table th {
    border: 1px solid rgba(151, 151, 151, 0.35);
}

.deleteInvoice__invoiceNo {
    /* width: 327px; */
    height: 40px;
}

.deleteInvoice__action {
    width: 100px;
}

.deleteInvoice_table  th {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
    padding: 12px 0 12px 10px;
}

.deleteInvoice_table td {
    height: 40px;
    padding: 12px 10px 12px 10px;
    font-size: 14px;
    line-height: 16px;
    color: #337AB7;
}

.deleteInvoice__muteText {
    font-size: 14px;
    line-height: 16px;
    color: #767676;
}

.deleteInvoive__deleteBtn {
    border: none;
    background: #FFFFFF;
}

.deleteInvoive__deleteBtn:focus {
    outline: none;
}

.deleteInvoice__warningText{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin: 0 53px 30px 53px;
}

.deleteInvoice__goBack {
    color: #EB5C24;
    text-decoration: underline;
}

.deleteInvoice__goBack:hover {
    color: #EB5C24;
    text-decoration: underline;
}

.deleteIncvoice__modalBts {
    display: flex;
    justify-content: space-between;
    margin: 0 53px 15px 53px;
}

.deleteInvoice__onlyTnxBtn {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EB5C24;
    border-radius: 4px;
    color: #EB5C24;
    padding: 0 8px;
}

.deleteInvoice__everyTnxBtn {
    height: 40px;
    background: #EB5C24;
    border-radius: 4px;
    color: white;
    padding: 0 20px;
    border: none;
}

.deleteInvoice__onlyTnxBtn:focus, .deleteInvoice__everyTnxBtn:focus {
    outline: none;
}

.deleteInovice__modal {
    width: 5000px;
    padding: 0px;
}

div.deleteInovice__modal > div.modal-content {
    padding: 0px;
}

.deleteInvoice__closeBtnCont {
    display: flex;
    justify-content: flex-end;
    padding: 15px 15px 0 0;
}

.deleteInvoice__closeBtn {
    background-color: #FFFFFF;
    border: none;
}

.deleteInvoice__closeBtn:focus {
    outline: none;
}