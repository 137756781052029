.rdt_TableCol {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    /* identical to box height */
    color: rgba(51, 51, 51, 0.85) !important;
}

.rdt_TableRow:nth-child(odd) {
    background-color: rgba(240, 241, 243, 0.35) !important;
    border-bottom-style: none !important;
    border-bottom-width: 0px !important;
    border-bottom-color: none !important;
}

.rdt_TableRow:nth-child(even) {
    background-color: rgba(255, 255, 255, 1) !important;
    border-bottom-style: none !important;
    border-bottom-width: 0px !important;
    border-bottom-color: none !important;
}

.rdt_TableCol {
    display: flex;
}

.rdt_TableCol_Sortable {
    flex: 1;
    justify-content: space-between;
}

.gnahTY {
    position: relative;
}

.rdt_TableCell {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.rdt_Pagination {
    position: sticky;
    top: 0;
    left: 0;
}

.rdt_TableCell div:first-child {
    white-space: normal !important;
}

.gCcqTE {
    padding-right: 20px !important;
    padding-left: 10px !important;
}

.jzCzGz {
    padding-right: 20px !important;
    padding-left: 10px !important;
}

.rdt_Pagination > select {
    padding-right: 20px !important;
    padding-left: 10px !important;
}