.uploadInvoices {
    /* height: 86vh; */
    /* padding-bottom: 50px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 30px;
    margin-bottom: 100px;
}

.uploadInvoices__container {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-height: 425px;
    /* width: 1108px; */
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.uploadInvoices__stepper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    min-width: 700px;
}

.uploadInvoives__horizontalLine {
    width: 100%;
    margin: 20px 0px;
    border-top: 1px solid rgba(151,151,151,0.2)
}

.uploadInvoices__downloadTemplate {
    width: 160px;
    height: 40px;
    background: #EB5C24;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 12px 18px;
    text-decoration: none;
    margin-bottom: 10px;
}

.uploadInvoices__downloadTemplate.active {
    width: 160px;
    height: 40px;
    background: #F39672;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 12px 18px;
    text-decoration: none;
    margin-bottom: 10px;
}

.uploadInvoices__downloadTemplate:hover {
    text-decoration: none;
    color: #FFFFFF;
}

.uploadInvoives__templateHeading {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
    margin-bottom: 10px;
}

.uploadInvoices__downloadTemplate:focus {
    outline: none;
}

.uploadInvoives__heading {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgba(51, 51, 51, 0.85);
    margin-bottom: 20px;
}

.uploadInvoices__stepContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadInvoices__stepperCircle {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #EB5C24;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadInvoices__uploadArea {
    /* width: 880px; */
    width: 75%;
    height: 193px;
    border-radius: 4px;
    border: 1px dashed #DBDBDA;
    background-color: #FAFAFA;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* justify-content: center; */
}

.uploadInvoices__uploadArea.drag {
    /* width: 880px; */
    width: 75%;
    height: 193px;
    border-radius: 4px;
    border: 1px dashed #EB5C24;
}

.uploadInvoices__uploadArea.success {
    /* width: 880px; */
    width: 75%;
    height: 193px;
    border-radius: 4px;
    border: 1px dashed #EB5C24;
    background-color: #FFFAF8;
}

.uploadInvoices__uploadArea.fail {
    /* width: 880px; */
    width: 75%;
    height: 193px;
    border-radius: 4px;
    border: 1px dashed #FF0000;
    background-color: #FFF8F8;
}

.uploadInvoices__uploadingSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadInvoices__progressUpload {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 50px;
}

.uploadingInvoices__uploadingText {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
    margin-bottom: 5px;
}

.uploadingInvoices__uploadedText.accepted {
    font-size: 14px;
    line-height: 16px;
    color: olivedrab;
    margin-bottom: 5px;
}

.uploadingInvoices__uploadedText {
    font-size: 14px;
    line-height: 16px;
    color: green;
    margin-bottom: 5px;
}

.uploadingInvoices__uploadedText.fail {
    font-size: 14px;
    line-height: 16px;
    color:red;
    margin-bottom: 5px;
}

.uploadInvoices__list {
    display: flex;
    justify-content: space-between;
    height: 100%;
    list-style: none;
    margin: 0 15px;
    align-items: center;
}

.uploadInvoices__list > span {
    font-size: 12px;
    line-height: 14px;
    color: rgba(51, 51, 51, 0.85);
}

.uploadInvoices__percentage {
    font-size: 10px;
    line-height: 12px;
    color: #979797;
}

.uploadInvoices__showingProgress {
    /* width: 780px; */
    width: 100%;
    height: 46px;
    background: #FFFFFF;
    border-radius: 4px;

    font-size: 12px;
    line-height: 14px;
    color: rgba(51, 51, 51, 0.85);
}

.progress {
    background-color: #FFFFFF;
    height: 2px;
}

.progress-bar {
    background-color: #EB5C24;
}

.uploadInvoices__doNotRefresh {
    font-size: 10px;
    line-height: 12px;
    color: rgba(51, 51, 51, 0.85);
    margin-top: 5px;
}


.uploadInvoices__fileSize {
    font-size: 12px;
    line-height: 14px;
    color: #979797;
    margin: 20px 0px;
}

.uploadInvoices__dragDropText {
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
    margin: 6px 0px;
}

.uploadInvoices__orText {
    font-size: 14px;
    line-height: 16px;
    color: #979797;
    margin-bottom: 8px;
}

.uploadInvoices__retry {
    border: none;
    background: none;
    outline: none;
    font-size: 12px;
    line-height: 14px;
    color: #337AB7;
    margin-right: 15px;
}

.uploadInvoices__retry:focus {
    outline: none;
}

.uploadInvoices__uploadBtn {
    width: 140px;
    height: 40px;
    background: #EB5C24;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    border: none
}

.uploadInvoces__successfulRows {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
}

.uploadInvoices__mappingError {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 10px 0 20px 0;
}

.uploadInvoices__downloadError {
    background-color: #FFFFFF;
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: #337AB7;
    text-decoration: none;
}

.uploadInvoices__downloadError:hover {
    outline: none;
    text-decoration: none;
    color: #337AB7;
}

.uploadInvoices__finishText {
    font-size: 14px;
    line-height: 16px;
    color: #767676;
    margin: 40px 0 10px 0;
}

.uploadInvoices__uploadBtn:focus {
    outline: none
}

.uploadInvoices__btns {
    display: flex;
    justify-content: space-between;
    /* width: 1108px; */
    width: 80%;
    margin-top: 30px;
}

.uploadInvoices__cancle {
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.85);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.85);
}

.uploadInvoices__prev {
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EB5C24;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: #EB5C24;
    margin-right: 20px;
}


.uploadInvoices__next {
    width: 100px;
    height: 40px;
    background: #F39672;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.uploadInvoices__next.active {
    width: 100px;
    height: 40px;
    background:#EB5C24;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.uploadInvoices__cancle:focus, .uploadInvoices__prev:focus, .uploadInvoices__next:focus {
    outline: none;
}

.uploadInvoices__mappedText {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* color: #EB5C24; */
    color: rgba(51, 51, 51, 0.85);
    margin: 10px 0px;
}

.uploadInvoices__mappedText.fail {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FF0000;
    margin: 10px 0px;
}

